import { dateFormat } from 'helpers/StringNumberFunction/FormatDate'
import { amtStr, amtStr4Dec } from 'helpers/StringNumberFunction/NumFormatters'

export const ELetterServiceGP = ({
  docType,
  previewDetail,
  allpreviewDetail,
  paramVal,
  ItemIndex,
}) => {
  const creditorDetails = {
    '[SupplierName]': previewDetail?.Supplier?.CompanyName,
    '[SupplierAddress]': `${previewDetail?.Supplier?.Address?.address}<br />
          ${
            previewDetail?.Supplier?.Address?.country == 'Singapore'
              ? `${previewDetail?.Supplier?.Address?.country} ${previewDetail?.Supplier?.Address?.postCode}`
              : `${previewDetail?.Supplier?.Address?.postCode} ${previewDetail?.Supplier?.Address?.city}, 
            ${previewDetail?.Supplier?.Address?.state}, ${previewDetail?.Supplier?.Address?.country}`
          }
           <br />`,
    '[SupplierContactName]': previewDetail?.Supplier?.CreditorContact[0]?.Name!,
    '[SupplierContactNo]':
      previewDetail?.Supplier?.CreditorContact[0]?.ContactNo! ?? '',
    '[SupplierContactEmail]':
      previewDetail?.Supplier?.CreditorContact[0]?.Email! ?? '',
    // '[SupplierCompanyRegNo]': previewDetail?.Supplier?.CompanyRegNo! ?? '',
    // '[SupplierBankAccName]': previewDetail?.Supplier?.BankName! ?? '',
    // '[SupplierBankAccNo]': previewDetail?.Supplier?.BankAccountNo! ?? '',
    // '[SupplierTIN]': previewDetail?.Supplier?.einvoice_TIN! ?? '',
    '[SupplierSSTRegNo]': previewDetail?.Supplier?.SSTRegNo! ?? '',
    '[SupplierGSTRegNo]': previewDetail?.Supplier?.GSTRegNo! ?? '',
  }

  switch (docType) {
    case 'direct-po':
      return Object.assign(paramVal, creditorDetails, {
        // Right Header Info
        '[DocumentNo]': previewDetail?.DocNo!,
        '[DocumentDate]': dateFormat(previewDetail?.DocDate!),
        '[CreditTerm]': previewDetail?.CreditTerm!,
        '[DeliveryLocation]': previewDetail?.DeliveryLocation?.Name!,
        '[DeliveryDate]': dateFormat(previewDetail?.ExpectedDate!),
        '[SpecialInstruction]': previewDetail?.Instruction!,

        // Item Info
        items: allpreviewDetail?.POItem?.map((el, i) => {
          return {
            '[Index]': ItemIndex + i + 1,
            '[ItemDescription]': el?.Item?.Name,
            '[Department]': el?.CostCentre?.Name,
            '[DeptCd]': el?.CostCentre?.Code,
            '[DeptCtNm]': el?.CostCentre?.FirstCostCentre?.Name,
            '[DeptCtCd]': el?.CostCentre?.FirstCostCentre?.Code,
            '[UOM]': el?.UOM?.Code,
            '[Quantity]': amtStr4Dec(el?.OrderedQty),
            '[UnitPrice]': amtStr4Dec(el?.UnitPrice),
            '[BaseAmt]': amtStr(el?.BaseAmt),
            '[TaxAmt]': amtStr(el?.TaxAmt),
            '[TotalAmt]': amtStr(el?.TotalAmt),
          }
        }),
        '[SumBaseAmt]': amtStr(
          allpreviewDetail?.POItem?.reduce((a, b) => a + Number(b?.BaseAmt!), 0)
        ),
        '[SumTaxAmt]': amtStr(
          allpreviewDetail?.POItem?.reduce((a, b) => a + Number(b?.TaxAmt!), 0)
        ),
        '[SumTotalAmt]': amtStr(
          allpreviewDetail?.POItem?.reduce(
            (a, b) => a + Number(b?.TotalAmt!),
            0
          )
        ),
      })

    case 'generate-po-from-pr':
      return Object.assign(paramVal, creditorDetails, {
        // Right Header Info
        '[DocumentNo]': previewDetail?.DocNo!,
        '[DocumentDate]': dateFormat(previewDetail?.DocDate!),
        '[CreditTerm]': previewDetail?.CreditTerm!,
        '[DeliveryLocation]': previewDetail?.DeliveryLocation?.Name!,
        '[DeliveryDate]': dateFormat(previewDetail?.ExpectedDate!),
        '[SpecialInstruction]': previewDetail?.Instruction!,

        // Item Info
        items: previewDetail?.POItem?.map((el, i) => {
          return {
            '[Index]': i + 1,
            '[ItemDescription]': el?.Item?.Name,
            '[Department]': el?.CostCentre?.Name,
            '[DeptCd]': el?.CostCentre?.Code,
            '[DeptCtNm]': el?.CostCentre?.FirstCostCentre?.Name,
            '[DeptCtCd]': el?.CostCentre?.FirstCostCentre?.Code,
            '[UOM]': el?.UOM?.Code,
            '[Quantity]': amtStr4Dec(el?.OrderedQty),
            '[UnitPrice]': amtStr4Dec(el?.UnitPrice),
            '[BaseAmt]': amtStr(el?.BaseAmt),
            '[TaxAmt]': amtStr(el?.TaxAmt),
            '[TotalAmt]': amtStr(el?.TotalAmt),
          }
        }),
        '[SumBaseAmt]': amtStr(
          allpreviewDetail?.POItem?.reduce((a, b) => a + Number(b?.BaseAmt!), 0)
        ),
        '[SumTaxAmt]': amtStr(
          allpreviewDetail?.POItem?.reduce((a, b) => a + Number(b?.TaxAmt!), 0)
        ),
        '[SumTotalAmt]': amtStr(
          allpreviewDetail?.POItem?.reduce(
            (a, b) => a + Number(b?.TotalAmt!),
            0
          )
        ),
      })

    case 'centralised-po':
      return Object.assign(paramVal, creditorDetails, {
        // Right Header Info
        '[DocumentNo]': previewDetail?.DocNo!,
        '[DocumentDate]': dateFormat(previewDetail?.DocDate!),
        '[CreditTerm]': previewDetail?.CreditTerm!,
        '[DeliveryLocation]': previewDetail?.DeliveryLocation?.Name!,
        '[DeliveryDate]': dateFormat(previewDetail?.ExpectedDate!),
        '[SpecialInstruction]': previewDetail?.Instruction!,

        // Item Info
        items: previewDetail?.POItem?.map((el, i) => {
          return {
            '[Index]': i + 1,
            '[ItemDescription]': el?.Item?.Name,
            '[Department]': el?.CostCentre?.Name,
            '[DeptCd]': el?.CostCentre?.Code,
            '[DeptCtNm]': el?.CostCentre?.FirstCostCentre?.Name,
            '[DeptCtCd]': el?.CostCentre?.FirstCostCentre?.Code,
            '[UOM]': el?.UOM?.Code,
            '[Quantity]': amtStr4Dec(el?.OrderedQty),
            '[UnitPrice]': amtStr4Dec(el?.UnitPrice),
            '[BaseAmt]': amtStr(el?.BaseAmt),
            '[TaxAmt]': amtStr(el?.TaxAmt),
            '[TotalAmt]': amtStr(el?.TotalAmt),
          }
        }),
        '[SumBaseAmt]': amtStr(
          allpreviewDetail?.POItem?.reduce((a, b) => a + Number(b?.BaseAmt!), 0)
        ),
        '[SumTaxAmt]': amtStr(
          allpreviewDetail?.POItem?.reduce((a, b) => a + Number(b?.TaxAmt!), 0)
        ),
        '[SumTotalAmt]': amtStr(
          allpreviewDetail?.POItem?.reduce(
            (a, b) => a + Number(b?.TotalAmt!),
            0
          )
        ),
      })

    case 'purchase-requisition':
      return Object.assign(paramVal, creditorDetails, {
        // Right Header Info
        '[RequestedBy]': previewDetail?.RequestedBy!,
        '[ContactPerson]': previewDetail?.ContactNo!,
        '[DocumentNo]': previewDetail?.DocNo!,
        '[DocumentDate]': dateFormat(previewDetail?.DocDate!),
        '[DeliveryLocation]': previewDetail?.DeliveryLocation?.Name!,
        '[DeliveryDate]': dateFormat(previewDetail?.ExpectedDate!),
        '[Justification]': previewDetail?.Instruction!,

        // Item Info
        items: previewDetail?.PRItem?.map((el, i) => {
          return {
            '[Index]': i + 1,
            '[ItemDescription]': el?.Item?.Name,
            '[Department]': el?.CostCentre?.Name,
            '[DeptCd]': el?.CostCentre?.Code,
            '[DeptCtNm]': el?.CostCentre?.FirstCostCentre?.Name,
            '[DeptCtCd]': el?.CostCentre?.FirstCostCentre?.Code,
            '[UOM]': el?.UOM?.Code,
            '[Quantity]': amtStr4Dec(el?.Quantity),
            '[UnitCost]': amtStr4Dec(el?.UnitCost),
            '[TotalUnitCost]': amtStr(el?.TotalUnitCost),
          }
        }),
        '[SumTotalUnitCost]': amtStr(
          allpreviewDetail?.PRItem?.reduce(
            (a, b) => a + Number(b?.TotalUnitCost!),
            0
          )
        ),
      })

    case 'delivery-order':
      return Object.assign(paramVal, creditorDetails, {
        // Right Header Info
        '[DocumentNo]': previewDetail?.DocNo!,
        '[DocumentDate]': dateFormat(previewDetail?.DocDate!),
        '[DeliveryLocation]': previewDetail?.DeliveryLocation?.Name!,
        '[DONo]': previewDetail?.DoNo!,
        '[DODate]': dateFormat(previewDetail?.TransactionDate!),

        // Item Info
        items: previewDetail?.DOItem?.map((el, i) => {
          return {
            '[PONo]': !!el?.POItem?.POHeader?.DocNo
              ? el?.POItem?.POHeader?.DocNo!
              : el?.ReplacedGRTNItem?.DOItem?.POItem?.POHeader?.DocNo!,
            '[ItemDescription]': !!el?.POItem?.Item?.Name
              ? el?.POItem?.Item?.Name!
              : el?.ReplacedGRTNItem?.DOItem?.POItem?.Item?.Name!,
            '[Warehouse]': !!el?.POItem?.Item?.IsInventory
              ? el?.POItem?.Warehouse?.Name ?? '-'
              : el?.ReplacedGRTNItem?.DOItem?.POItem?.Item?.IsInventory
              ? el?.ReplacedGRTNItem?.DOItem?.POItem?.Warehouse?.Name
              : '-',
            '[UOM]': el?.POItem?.UOM?.Code
              ? el?.POItem?.UOM?.Code
              : el?.ReplacedGRTNItem?.DOItem?.POItem?.UOM?.Code,
            '[OrderQty]': !!el?.POItem?.OrderedQty
              ? amtStr4Dec(el?.POItem?.OrderedQty)
              : amtStr4Dec(el?.ReplacedGRTNItem?.DOItem?.POItem?.OrderedQty),
            '[ReceivedQty]': amtStr4Dec(el?.AcceptedQty),
            '[ReceivedAmt]': amtStr(el?.AcceptedAmt),
          }
        }),
        '[SumOrderQty]': amtStr(
          allpreviewDetail?.DOItem?.reduce(
            (a, b) => a + Number(b?.OrderedQty!),
            0
          )
        ),
        '[SumReceivedQty]': amtStr(
          allpreviewDetail?.DOItem?.reduce(
            (a, b) => a + Number(b?.AcceptedQty!),
            0
          )
        ),
        '[SumReceivedAmt]': amtStr(
          allpreviewDetail?.DOItem?.reduce(
            (a, b) => a + Number(b?.AcceptedAmt!),
            0
          )
        ),
      })

    case 'good-return-note':
      return Object.assign(paramVal, creditorDetails, {
        // Right Header Info
        '[DocumentNo]': previewDetail?.DocNo!,
        '[DocumentDate]': dateFormat(previewDetail?.DocDate!),
        '[DONo]': previewDetail?.RefNo!,
        '[ReturnLocation]': previewDetail?.DeliveryLocation?.Name!,
        '[ReturnDate]': dateFormat(previewDetail?.TransactionDate!),

        // Item Info
        items: previewDetail?.GRTNHeaderItem?.map((el, i) => {
          return {
            '[GRNNo]': el?.DOItem?.DOHeader?.DocNo,
            '[ItemDescription]': el?.DOItem?.POItem?.Item?.Name,
            '[Warehouse]': !!el?.DOItem?.POItem?.Item?.IsInventory
              ? el?.DOItem?.POItem?.Warehouse?.Name
              : '-',
            '[UOM]': el?.DOItem?.POItem?.Item?.UOM?.Code,
            '[ReceivedQty]': amtStr4Dec(el?.DOItem?.AcceptedQty),
            '[ReturnedQty]': amtStr4Dec(el?.ReturnedQty),
            '[ReturnedAmt]': amtStr(el?.ReturnedAmt),
          }
        }),

        '[SumReceivedQty]': amtStr4Dec(
          allpreviewDetail?.GRTNHeaderItem?.reduce(
            (a, b) => a + Number(b?.DOItem?.AcceptedQty!),
            0
          )
        ),
        '[SumReturnedQty]': amtStr4Dec(
          allpreviewDetail?.GRTNHeaderItem?.reduce(
            (a, b) => a + Number(b?.ReturnedQty!),
            0
          )
        ),
        '[SumReturnedAmt]': amtStr(
          allpreviewDetail?.GRTNHeaderItem?.reduce(
            (a, b) => a + Number(b?.ReturnedAmt!),
            0
          )
        ),
      })
  }
}
