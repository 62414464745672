import IconText from '@ifca-root/react-component/src/components/Typography/IconText'
import PENDING_ICON from 'assets/einvoice/einvoice-pending.svg'
import REJECTED_ICON from 'assets/einvoice/einvoice-reject.svg'
import COMPELETED_ICON from 'assets/einvoice/einvoice-completed.svg'
import React, { useState } from 'react'
import { formatDate } from '@ifca-root/react-component/src/helpers/StringNumberFunction/formatDate'
import { formatTime } from 'helpers/StringNumberFunction/FormatDate'
import { EInvoiceDialogStatus } from 'containers/APModule/EInvoiceComponent/EInvoiceDialog'

export const EInvoiceStatus = (props?: any) => {
  const { einvoiceStatus, data } = props
  const [openEinvStatusDialog, setOpenEinvStatusDialog] = useState(false)

  return (
    <>
      {(einvoiceStatus === 'NEW' ||
        einvoiceStatus === 'RECORDED' ||
        einvoiceStatus === 'PENDING_SEND' ||
        einvoiceStatus === 'PENDING_VALIDATION') && (
        <span onClick={() => setOpenEinvStatusDialog(true)}>
          <IconText
            icon={
              <img
                src={PENDING_ICON}
                style={{
                  width: '20px',
                }}
              />
            }
          />
        </span>
      )}
      {(einvoiceStatus === 'CANCELLED_SEND' ||
        einvoiceStatus === 'FAILED_VALIDATION' ||
        einvoiceStatus === 'REJECT_REQUEST' ||
        einvoiceStatus === 'REJECTED' ||
        einvoiceStatus === 'FAILED_SEND' ||
        einvoiceStatus === 'CANCELLED_INVOICE') && (
        <span onClick={() => setOpenEinvStatusDialog(true)}>
          <IconText
            icon={
              <img
                src={REJECTED_ICON}
                style={{
                  width: '20px',
                }}
              />
            }
          />
        </span>
      )}
      {(einvoiceStatus === 'VALIDATED' ||
        einvoiceStatus === 'CONSOLIDATED') && (
        <span onClick={() => setOpenEinvStatusDialog(true)}>
          <IconText
            icon={
              <img
                src={COMPELETED_ICON}
                style={{
                  width: '20px',
                }}
              />
            }
          />
        </span>
      )}

      <EInvoiceDialogStatus
        openDialog={openEinvStatusDialog}
        setOpenDialog={setOpenEinvStatusDialog}
        data={data}
        date={data?.einvoice?.einvoice_date}
        status={data?.einvoice?.einvoice_status}
      />
    </>
  )
}
