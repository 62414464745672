import DateFnsUtils from '@date-io/date-fns'
import { yupResolver } from '@hookform/resolvers'
import theme from '@ifca-root/react-component/src/assets/theme'
import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog'
import { AccountFooter } from '@ifca-root/react-component/src/components/Footer/AccountFooter'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import { smTitle } from '@ifca-root/react-component/src/global/TitleVariable'
import {
  Button,
  Grid,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  TextField,
  useMediaQuery,
} from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import AdvanceFilterCardExpansion from 'components/CardExpansion/AdvanceFilterCardExpansion'
import { ErrorDialog } from 'components/Dialog/ErrorDialog'
import { TotalAmountFooter } from 'components/Footer/TotalAmountFooter'
import SnackBarContext from 'containers/App/Store/SnackBarContext'
import {
  EInvoiceTrxType,
  useCreateEinvoiceConsolidateDocTypeAllMutation,
  useCreateEinvoiceConsolidateMutation,
  useGetCompanyNameQuery,
  useGetDebtorAccountLazyQuery,
  useGetDocToConsolidateLazyQuery,
} from 'generated/graphql'
import { SystemMsgs } from 'helpers/Messages/SystemMsg'
import {
  dateFormat,
  formatDashDate,
} from 'helpers/StringNumberFunction/FormatDate'
import {
  amtStr,
  backDateNDays,
} from 'helpers/StringNumberFunction/NumFormatters'
import { CommonYupValidation } from 'helpers/YupSchema/yup'
import React, { useContext, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import NumberFormat from 'react-number-format'
import { useHistory, useParams } from 'react-router'
import * as yup from 'yup'
import { ConsolidateTable } from './ConsolidateTable/ConsolidateTable'

export const ARConsolidateEInvoiceForm = (props: any) => {
  /*
  ===========================================
  =================VARIABLES=================
  ===========================================
  */

  let history = useHistory()
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  })
  const user = JSON.parse(localStorage.getItem('loggedInUser'))
  const { setOpenSnackBar, setSnackBarMsg } = useContext(SnackBarContext) as any
  const today = new Date()

  /*
  ===========================================
  =================USE STATE=================
  ===========================================
  */

  const { CompanyID }: any = useParams()
  const [itemData, setItemData] = useState([])
  const [errMsg, setErrMsg] = useState<string>('')
  const [remapData, setRemapData] = useState(null)
  const [docType, setDocType] = useState('Invoice')
  const [startDate, setStartDate] = useState(
    dateFormat(
      new Date(today.getFullYear(), today.getMonth() - 1, 1).toISOString()
    )
  )
  const [endDate, setEndDate] = useState(
    dateFormat(new Date(today.getFullYear(), today.getMonth(), 0).toISOString())
  )
  const [labelState, setLabelState] = useState<string>()
  const [errorDia, setErrorDia] = useState<boolean>(false)
  const [openDialog, setOpenDialog] = useState<boolean>(false)
  const [expanded, setExpanded] = useState(false)

  /*
  ====================================
  ==============QUERY=================
  ====================================
  */

  const {
    loading: CompanyLoading,
    data: { getCompany } = { getCompany: [] },
  } = useGetCompanyNameQuery({
    fetchPolicy: 'network-only',
    variables: { CompanyID: CompanyID },
    onCompleted: ({ getCompany }) => {
      if (!!getCompany) {
        setLabelState(getCompany[0]?.Name)
        loadDocs({
          variables: {
            CompanyID,
            DocType: docType === 'All' ? null : docType,
            DocNo: '',
            DebtorName: '',
            StartDate: formatDashDate(
              new Date(
                today.getFullYear(),
                today.getMonth() - 1,
                1
              ).toISOString()
            ),
            EndDate: formatDashDate(
              new Date(today.getFullYear(), today.getMonth(), 0).toISOString()
            ),
          },
        })
        loadDebtor({
          variables: {
            CompanyID: CompanyID,
          },
        })
      }
    },
  })

  const [
    loadDebtor,
    {
      loading: DebtorAccountLoading,
      data: { getDebtorAccount } = { getDebtorAccount: [] },
    },
  ] = useGetDebtorAccountLazyQuery({
    fetchPolicy: 'network-only',
    variables: { CompanyID: CompanyID },
  })

  const [
    loadDocs,
    {
      loading: ConsolidateLoading,
      data: { getDocToConsolidate } = { getDocToConsolidate: [] },
    },
  ] = useGetDocToConsolidateLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: ({ getDocToConsolidate }) => {
      let arry = []
      if (getDocToConsolidate?.length > 0) {
        getDocToConsolidate?.map((el, index) => {
          arry.push({
            ...el,
          })
        })
        setItemData(arry)
      }
    },
  })

  /*
  ==========================================
  =================MUTATION=================
  ==========================================
  */

  const [
    createEinvoiceConsolidate,
    {
      loading: createEinvoiceConsolidateLoading,
      called: createEinvoiceConsolidateCalled,
      error: createEinvoiceConsolidateError,
    },
  ] = useCreateEinvoiceConsolidateMutation({
    onError: error => {
      setOpenDialog(false)
      let errorMessage = error.message.substring(15)
      console.log('ERROR', error)
      setErrorDia(true)
      setErrMsg(errorMessage)
    },
    onCompleted: () => {
      setOpenDialog(false)
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.createNewRecord())

      setTimeout(() => {
        history.push({
          pathname: `/account-receivable/${CompanyID}/consolidate-einvoice`,
        })
      }, 500)
    },
  })

  const [
    createEinvoiceConsolidateDocTypeAll,
    { loading: createEinvoiceConsolidateDocTypeAllLoading },
  ] = useCreateEinvoiceConsolidateDocTypeAllMutation({
    onError: error => {
      setOpenDialog(false)
      let errorMessage = error.message.substring(15)
      console.log('ERROR', error)
      setErrorDia(true)
      setErrMsg(errorMessage)
    },
    onCompleted: () => {
      setOpenDialog(false)
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.createNewRecord())
      setTimeout(() => {
        history.push({
          pathname: `/account-receivable/${CompanyID}/consolidate-einvoice`,
        })
      }, 500)
    },
  })

  /*
  ==========================================
  =================USE FORM=================
  ==========================================
  */

  const FormSchema = yup.object().shape({
    Description: CommonYupValidation.requireField(SystemMsgs.description()),
  })

  const { handleSubmit, register, control, errors } = useForm({
    resolver: yupResolver(FormSchema),
  })

  const {
    register: registerFilter,
    control: controlFilter,
    setValue: setValueFilter,
    getValues: getValueFilter,
    watch: watchFilter,
  } = useForm()

  // ==== Table Pagination =====
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [page, setPage] = useState(0)

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  /*
  ==========================================
  ==============FUNCTION & MISC=============
  ==========================================
  */

  const onSubmitFilter = () => {
    const selectedDocType =
      getValueFilter('DocType') === 'All' ? 'All' : getValueFilter('DocType')

    setDocType(selectedDocType)
    setStartDate(
      dateFormat(new Date(getValueFilter('StartDate')).toISOString())
    )
    setEndDate(dateFormat(new Date(getValueFilter('EndDate')).toISOString()))

    loadDocs({
      variables: {
        CompanyID: CompanyID,
        DocType: selectedDocType,
        DocNo: getValueFilter('DocNo') ?? '',
        DebtorName: getValueFilter('DebtorName') ?? '',
        StartDate: formatDashDate(
          new Date(getValueFilter('StartDate')).toISOString()
        ),
        EndDate: formatDashDate(
          new Date(getValueFilter('EndDate')).toISOString()
        ),
      },
    })
  }

  const resetFilter = () => {
    setDocType('Invoice')
    setValueFilter('DocType', 'Invoice')
    setValueFilter(
      'StartDate',
      new Date(today.getFullYear(), today.getMonth() - 1, 1)
    )
    setValueFilter(
      'EndDate',
      new Date(today.getFullYear(), today.getMonth(), 0)
    )
    setValueFilter('DebtorName', '')
    setValueFilter('DocNo', '')

    setStartDate(
      dateFormat(
        new Date(today.getFullYear(), today.getMonth() - 1, 1).toISOString()
      )
    )
    setEndDate(
      dateFormat(
        new Date(today.getFullYear(), today.getMonth(), 0).toISOString()
      )
    )

    loadDocs({
      variables: {
        CompanyID,
        DocType: 'Invoice',
        DocNo: '',
        DebtorName: '',
        StartDate: formatDashDate(
          new Date(today.getFullYear(), today.getMonth() - 1, 1).toISOString()
        ),
        EndDate: formatDashDate(
          new Date(today.getFullYear(), today.getMonth(), 0).toISOString()
        ),
      },
    })

    loadDebtor({
      variables: {
        CompanyID: CompanyID,
      },
    })
  }

  const footerOptions = [
    {
      disabled: getDocToConsolidate?.length === 0 ? true : false,
      name: 'Send to LHDN',
      onClick: () => {
        setOpenDialog(true)
      },
      color: 'primary',
      props: { type: 'submit' },
      style: { float: 'right', margin: isDesktop ? null : '0 auto' },
    },
  ]

  const documentType = [
    {
      name: 'Invoice',
      value: 'Invoice',
      einvoice_type: EInvoiceTrxType.Invoice,
    },
    {
      name: 'Credit Note',
      value: 'Credit Note',
      einvoice_type: EInvoiceTrxType.CreditNote,
    },
    {
      name: 'Debit Note',
      value: 'Debit Note',
      einvoice_type: EInvoiceTrxType.DebitNote,
    },
    // {
    //   name: 'All',
    //   value: 'All',
    //   einvoice_type: null,
    // },
  ]

  const totalDocAmt = getDocToConsolidate?.reduce(
    (total, current) => total + Number(current?.DocAmt),
    0
  )

  const totalTaxAmt = getDocToConsolidate?.reduce(
    (total, current) => total + Number(current?.TaxAmt),
    0
  )

  /*
  =====================================
  ==============ONSUBMIT=============
  =====================================
  */

  const trimData = (data, itemData) => {
    // Separate arrays for different document types
    const invoices = []
    const creditNotes = []
    const debitNotes = []
    const filteredData = []

    // Categorize documents into different types
    itemData?.map(doc => {
      switch (doc.DocType) {
        case 'Invoice':
          invoices.push(doc)
          break
        case 'Credit Note':
          creditNotes.push(doc)
          break
        case 'Debit Note':
          debitNotes.push(doc)
          break
      }
    })

    if (invoices.length > 0) {
      const totalInvDocAmt = invoices?.reduce(
        (total, current) => total + Number(current?.DocAmt),
        0
      )
      const totalInvTaxAmt = invoices?.reduce(
        (total, current) => total + Number(current?.TaxAmt),
        0
      )

      filteredData.push({
        CompanyID: CompanyID,
        DocDate: formatDashDate(new Date(data?.DocDate)?.toISOString()),
        Description: data?.Description,
        Amount: Number(
          parseFloat(totalInvDocAmt) - parseFloat(totalInvTaxAmt ?? 0.0)
        ),
        TaxAmt: Number(parseFloat(totalInvTaxAmt ?? 0.0)),
        DocAmt: Number(parseFloat(totalInvDocAmt)),
        einvoice_trx_type: EInvoiceTrxType.Invoice,
        consolidateItems: invoices?.map(x => {
          return {
            RefRecordID: x?.RefRecordID,
            RefTable: x?.RefTable,
            Amount: Number(x?.DocAmt) - Number(x?.TaxAmt),
            TaxAmt: Number(x?.TaxAmt),
            DocAmt: Number(x?.DocAmt),
            DocType: x?.DocType,
          }
        }),
      })
    }

    if (creditNotes.length > 0) {
      const totalCNDocAmt = creditNotes?.reduce(
        (total, current) => total + Number(current?.DocAmt),
        0
      )
      const totalCNTaxAmt = creditNotes?.reduce(
        (total, current) => total + Number(current?.TaxAmt),
        0
      )

      filteredData.push({
        CompanyID: CompanyID,
        DocDate: formatDashDate(new Date(data?.DocDate)?.toISOString()),
        Description: data?.Description,
        Amount: Number(
          parseFloat(totalCNDocAmt) - parseFloat(totalCNTaxAmt ?? 0.0)
        ),
        TaxAmt: Number(parseFloat(totalCNTaxAmt ?? 0.0)),
        DocAmt: Number(parseFloat(totalCNDocAmt)),
        einvoice_trx_type: EInvoiceTrxType.CreditNote,
        consolidateItems: creditNotes?.map(x => {
          return {
            RefRecordID: x?.RefRecordID,
            RefTable: x?.RefTable,
            Amount: Number(x?.DocAmt) - Number(x?.TaxAmt),
            TaxAmt: Number(x?.TaxAmt),
            DocAmt: Number(x?.DocAmt),
            DocType: x?.DocType,
          }
        }),
      })
    }

    if (debitNotes.length > 0) {
      const totalDNDocAmt = debitNotes?.reduce(
        (total, current) => total + Number(current?.DocAmt),
        0
      )
      const totalDNTaxAmt = debitNotes?.reduce(
        (total, current) => total + Number(current?.TaxAmt),
        0
      )

      filteredData.push({
        CompanyID: CompanyID,
        DocDate: formatDashDate(new Date(data?.DocDate)?.toISOString()),
        Description: data?.Description,
        Amount: Number(
          parseFloat(totalDNDocAmt) - parseFloat(totalDNTaxAmt ?? 0.0)
        ),
        TaxAmt: Number(parseFloat(totalDNTaxAmt ?? 0.0)),
        DocAmt: Number(parseFloat(totalDNDocAmt)),
        einvoice_trx_type: EInvoiceTrxType.DebitNote,
        consolidateItems: debitNotes?.map(x => {
          return {
            RefRecordID: x?.RefRecordID,
            RefTable: x?.RefTable,
            Amount: Number(x?.DocAmt) - Number(x?.TaxAmt),
            TaxAmt: Number(x?.TaxAmt),
            DocAmt: Number(x?.DocAmt),
            DocType: x?.DocType,
          }
        }),
      })
    }

    return filteredData
  }

  const onSubmit = data => {
    if (getValueFilter('DocType') !== 'All') {
      createEinvoiceConsolidate({
        variables: {
          input: {
            CompanyID: CompanyID,
            DocDate: formatDashDate(new Date(data?.DocDate)?.toISOString()),
            Description: data?.Description,
            Amount: totalDocAmt - totalTaxAmt,
            TaxAmt: totalTaxAmt,
            DocAmt: totalDocAmt,
            einvoice_trx_type: documentType?.find(
              x => x?.value === getValueFilter('DocType')
            )?.einvoice_type,
          },
          itemInput: itemData?.map((x, i) => {
            return {
              RefRecordID: x?.RefRecordID,
              DocType: x?.DocType,
              Amount: Number(
                parseFloat(x?.DocAmt) - parseFloat(x?.TaxAmt ?? 0.0)
              ),
              TaxAmt: Number(parseFloat(x?.TaxAmt ?? 0.0)),
              DocAmt: Number(parseFloat(x?.DocAmt)),
            }
          }),
        },
      })
    } else {
      createEinvoiceConsolidateDocTypeAll({
        variables: {
          input: trimData(data, itemData),
        },
      })
    }
  }

  /*
  =====================================
  ==============PAGINATION=============
  =====================================
  */
  const separateThreshold = input => {
    let temp = []
    let noChange = []
    let tempItem = []

    getDocToConsolidate?.map((v, i) => {
      tempItem.push({ ...v, index: i + 1 })
      if ((i + 1) % rowsPerPage === 0) {
        temp.push({ ...input })
        tempItem = []
      } else if (getDocToConsolidate?.length === i + 1) {
        temp.push({ ...input })
      }
    })
    noChange.push(temp)
    temp = []
    return setRemapData(noChange.flat())
  }

  useEffect(() => {
    if (!!getDocToConsolidate) separateThreshold(getDocToConsolidate)
  }, [rowsPerPage])

  return (
    <>
      {CompanyLoading && <Loading />}
      {ConsolidateLoading && <Loading />}
      {DebtorAccountLoading && <Loading />}
      {createEinvoiceConsolidateLoading && <Loading />}
      {createEinvoiceConsolidateDocTypeAllLoading && <Loading />}

      <MainHeader
        mainBtn={'close'}
        onClick={() =>
          history.push({
            pathname: `/account-receivable/${CompanyID}/consolidate-einvoice`,
          })
        }
        smTitle={smTitle.ACCOUNT_RECEIVABLE}
        title={labelState}
        currency={user?.companyCurrencyCode}
        routeSegments={[
          { name: 'Account Receivable' },
          { name: `Consolidate e-Invoice`, current: true },
        ]}
      />

      <ContentWrapper footer>
        <List className="core-list">
          <AdvanceFilterCardExpansion
            customColor={true}
            customColorCode="#faf2e8"
            expansion={expanded}
            summary={
              <ListItem
                style={{
                  backgroundColor: '#faf2e8',
                  color: '#ff9800',
                }}
              >
                <ListItemText
                  primary={
                    <>
                      <span className={`xsTitle flex-space`}>
                        {`Doc Type: ${docType}`}
                        {`, From: ${startDate} To: ${endDate}`}
                      </span>
                    </>
                  }
                />
              </ListItem>
            }
          >
            <div
              style={{
                fontSize: '12px',
                marginBottom: '15px',
                display: 'block',
              }}
            >
              <Controller
                render={({ onChange, value }) => (
                  <TextField
                    label="Doc Type"
                    select
                    value={value}
                    fullWidth
                    onChange={e => {
                      onChange(e)
                    }}
                    style={{ marginTop: '8px', marginBottom: '12px' }}
                  >
                    {documentType?.map((el, index) => (
                      <MenuItem key={index} value={el?.value}>
                        {el.name}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
                onChange={e => {
                  setValueFilter('DocType', e?.target?.value)
                }}
                defaultValue={
                  documentType?.find(x => x?.value === docType)?.value
                }
                control={controlFilter}
                ref={registerFilter()}
                name="DocType"
                autoComplete="off"
                multiline={true}
                margin="dense"
              />

              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Controller
                  as={KeyboardDatePicker}
                  name="StartDate"
                  label="Doc Start Date"
                  control={controlFilter}
                  onChange={(date: Date | null) => {}}
                  format="dd/MM/yyyy"
                  value={watchFilter('StartDate')}
                  margin="normal"
                  allowKeyboardControl
                  ref={registerFilter}
                  KeyboardButtonProps={{ 'aria-label': 'change date' }}
                  className="left"
                  defaultValue={
                    new Date(today.getFullYear(), today.getMonth() - 1, 1)
                  }
                  showTodayButton
                  fullWidth
                />
              </MuiPickersUtilsProvider>

              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Controller
                  as={KeyboardDatePicker}
                  name="EndDate"
                  label="Doc End Date"
                  control={controlFilter}
                  onChange={(date: Date | null) => {
                    setValueFilter('EndDate', date)
                  }}
                  format="dd/MM/yyyy"
                  value={watchFilter('EndDate')}
                  margin="normal"
                  allowKeyboardControl
                  ref={registerFilter}
                  KeyboardButtonProps={{ 'aria-label': 'change date' }}
                  className="right"
                  defaultValue={
                    new Date(today.getFullYear(), today.getMonth(), 0)
                  }
                  showTodayButton
                  fullWidth
                />
              </MuiPickersUtilsProvider>

              {!DebtorAccountLoading && (
                <Controller
                  render={({ value, onChange }) => {
                    return (
                      <Autocomplete
                        options={
                          getDebtorAccount
                            ?.sort((a, b) => {
                              return a?.DebtorName?.localeCompare(b?.DebtorName)
                            })
                            ?.filter(x => {
                              return x?.einvoice_send_individually === false
                            }) || []
                        }
                        getOptionLabel={option => `${option?.DebtorName}`}
                        fullWidth
                        onChange={(value, newValue: any) => {
                          setValueFilter('DebtorName', newValue?.DebtorName)
                        }}
                        renderOption={(props, option) => {
                          return <span>{props?.DebtorName}</span>
                        }}
                        renderInput={(params: any) => {
                          return (
                            <TextField
                              {...params}
                              label="Debtor Name"
                              style={{
                                width: '100%',
                                marginTop: '8px',
                                marginBottom: '12px',
                              }}
                              margin="dense"
                            />
                          )
                        }}
                      />
                    )
                  }}
                  label="Debtor Name"
                  name="DebtorName"
                  autoComplete="off"
                  control={controlFilter}
                  multiline={true}
                  fullWidth
                  margin="dense"
                  ref={registerFilter}
                />
              )}

              <Controller
                as={TextField}
                style={{ marginTop: '8px', marginBottom: '12px' }}
                id="standard-basic"
                name="DocNo"
                label="Document No."
                autoComplete="off"
                control={controlFilter}
                fullWidth
                margin="dense"
                ref={registerFilter}
              />

              <Button
                type="reset"
                color="secondary"
                style={{
                  minWidth: '50px',
                  float: 'left',
                  marginBottom: '15px',
                }}
                variant="contained"
                onClick={() => {
                  resetFilter()
                }}
              >
                Reset
              </Button>

              <Button
                type="submit"
                color="primary"
                style={{
                  minWidth: '50px',
                  float: 'right',
                  marginBottom: '15px',
                }}
                variant="contained"
                onClick={() => {
                  onSubmitFilter()
                }}
              >
                Filter
              </Button>
            </div>
          </AdvanceFilterCardExpansion>
        </List>
        {/* Advance Filter */}

        {/* Table Section */}
        <ConsolidateTable
          data={getDocToConsolidate}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </ContentWrapper>

      <CommonDialog
        fullWidth={true}
        open={openDialog}
        onClose={() => {
          setOpenDialog(false)
        }}
        sections={{
          header: {
            title: 'Consolidate e-Invoice',
          },
          body: () => (
            <>
              <div
                className="content-container"
                style={{ marginTop: '-10px', fontSize: '16px' }}
              >
                <Grid
                  xs={7}
                  className="desc"
                  style={{ marginTop: '5px', fontSize: '16px' }}
                >
                  Doc Count:
                </Grid>

                <Grid xs={5}>
                  <Controller
                    as={<NumberFormat allowNegative={false} />}
                    thousandSeparator
                    customInput={TextField}
                    ref={register}
                    control={control}
                    margin="none"
                    name="DocCount"
                    defaultValue={getDocToConsolidate?.length}
                    InputProps={{
                      readOnly: true,
                      disableUnderline: true,
                      inputProps: {
                        style: { textAlign: 'right', fontSize: '16px' },
                      },
                      class: 'xxTitle',
                    }}
                    helperText={
                      getDocToConsolidate?.length > 100 &&
                      'Docs to consolidate cannot exceed 100'
                    }
                    errors={!!(getDocToConsolidate?.length > 100)}
                  />
                </Grid>

                <Grid
                  xs={7}
                  className="desc"
                  style={{ marginTop: '6px', fontSize: '16px' }}
                >
                  <span>Total Amount:</span>
                </Grid>

                <Grid xs={5}>
                  <Controller
                    as={<NumberFormat allowNegative={false} />}
                    thousandSeparator
                    customInput={TextField}
                    ref={register}
                    control={control}
                    margin="none"
                    name="Amount"
                    defaultValue={amtStr(totalDocAmt)}
                    decimalScale={2}
                    fixedDecimalScale
                    InputProps={{
                      readOnly: true,
                      disableUnderline: true,
                      inputProps: {
                        style: { textAlign: 'right', fontSize: '16px' },
                      },
                      class: 'xxTitle',
                    }}
                  />
                </Grid>

                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Controller
                    required
                    as={KeyboardDatePicker}
                    name="DocDate"
                    label="Document Date"
                    control={control}
                    onChange={(date: Date | null) => {}}
                    format="dd/MM/yyyy"
                    margin="dense"
                    allowKeyboardControl
                    ref={register}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                    defaultValue={new Date()}
                    showTodayButton
                    minDate={backDateNDays(3)}
                  />
                </MuiPickersUtilsProvider>

                <Controller
                  as={TextField}
                  id="standard-basic"
                  name="Description"
                  label="Description"
                  required
                  autoComplete="off"
                  control={control}
                  fullWidth
                  margin="dense"
                  helperText={errors?.Description?.message}
                  error={errors?.Description ? true : false}
                  ref={register}
                />
              </div>
            </>
          ),
          footer: {
            actions: [
              {
                displayText: 'Cancel',
                props: {
                  onClick: () => {
                    setOpenDialog(false)
                  },
                  variant: 'contained',
                  color: 'primary',
                },
              },

              {
                displayText: 'Proceed',
                props: {
                  onClick: () => {
                    handleSubmit(data => onSubmit(data))()
                  },
                  variant: 'contained',
                  color: 'primary',
                  disabled: !!(getDocToConsolidate?.length > 100),
                },
              },
            ],
          },
        }}
      />

      <TotalAmountFooter
        countRecord={
          getDocToConsolidate?.length > 0 ? getDocToConsolidate?.length : 0
        }
        taxInfoDetailWithRecord={true}
        docAmt={totalDocAmt}
        totalTaxAmt={totalTaxAmt}
      />

      <AccountFooter
        optionStyle={{ float: isDesktop ? 'right' : null }}
        options={footerOptions}
      />

      <ErrorDialog
        errorDia={errorDia}
        setErrorDia={setErrorDia}
        errorMsg={errMsg}
        errorHeaderMsg={'Error!'}
      />
    </>
  )
}
