import { dateFormat } from 'helpers/StringNumberFunction/FormatDate'
import { amtStr, amtStr4Dec } from 'helpers/StringNumberFunction/NumFormatters'
import { ELetterServiceAP } from './ELetterServiceAP/ELetterServiceAP'
import { ELetterServiceAR } from './ELetterServiceAR/ELetterServiceAR'
import { ELetterServiceGP } from './ELetterServiceGP/ELetterServiceGP'

const moduleParams = (
  accountType: string,
  docType: string,
  previewDetail,
  allpreviewDetail,
  getCompany,
  page,
  pageLength,
  getUsersByAccountAndSoftware,
  ItemIndex
) => {
  let CBPayment,
    CBReceipt,
    CBBankTransfer,
    ICStockReqquisition,
    ICStockRecceipt,
    ICStockIssuue,
    ICStockTransfer,
    ICStockTransfreRequisition,
    ICStockAdjustment,
    ICStockCostAdjustment

  let paramVal: any = {
    '[CompanyName]': getCompany[0]?.Name!,
    '[CompanyRegNo]': getCompany[0]?.CompanyRegNo!,
    '[CompanyAddress]': `${getCompany[0]?.Address?.address}
  <br />
  ${
    getCompany[0]?.Address?.country == 'Singapore'
      ? `${getCompany[0]?.Address?.country} ${getCompany[0]?.Address?.postCode}`
      : `${getCompany[0]?.Address?.postCode} ${getCompany[0]?.Address?.city}, ${getCompany[0]?.Address?.state}, ${getCompany[0]?.Address?.country}`
  }`,
    '[CompanySSTNo]': getCompany[0]?.SSTNo!,
    '[CompanyGSTNo]': getCompany[0]?.GSTNo!,
    '[CompanyTelNo]': getCompany[0]?.ContactNo!,
    'src="https://static.vecteezy.com/system/resources/previews/004/141/669/non_2x/no-photo-or-blank-image-icon-loading-images-or-missing-image-mark-image-not-available-or-image-coming-soon-sign-simple-nature-silhouette-in-frame-isolated-illustration-vector.jpg"': `src = ${getCompany[0]?.Logo?.fileURL}`,
    '[PageNo]': page + 1,
    '[TotalPage]': pageLength,
    '[CreatedBy]': getUsersByAccountAndSoftware?.find(
      x => x?.ID === previewDetail?.createdBy!
    )?.name!,
    '[CreatedDate]': dateFormat(previewDetail?.createdTs!),
    '[ApprovedBy]':
      getUsersByAccountAndSoftware?.find(
        x => x?.ID === previewDetail?.approvedBy!
      )?.name! ?? '',
    '[ApprovedDate]': !!previewDetail?.approvedTs
      ? dateFormat(previewDetail?.approvedTs!)
      : '',
    '[SubmittedBy]': getUsersByAccountAndSoftware?.find(
      x => x?.ID === previewDetail?.submittedBy!
    )?.name!,
    '[SubmittedDate]': !!previewDetail?.submittedTs
      ? dateFormat(previewDetail?.submittedTs!)
      : '',
  }

  if (!!previewDetail?.ApprovalWorkflow) {
    previewDetail?.ApprovalWorkflow[0]?.WorkflowStep?.filter(
      x => x?.ApprovalStatus === 'APPROVED'
    )?.map((el, i) => {
      const index = i + 1
      paramVal[`[ApprovedBy${index}]`] = !!el?.Name ? el?.Name : ''
      paramVal[`[ApprovedDate${index}]`] = !!el?.ApprovalDT
        ? dateFormat(el?.ApprovalDT)
        : ''
    })
  }

  switch (accountType) {
    case 'account-receivable':
      ELetterServiceAR({ docType, previewDetail, paramVal })
      break
    case 'account-payable':
      ELetterServiceAP({
        docType,
        previewDetail,
        allpreviewDetail,
        paramVal,
        ItemIndex,
      })
      break
    case '/': // General Purchase
      ELetterServiceGP({
        docType,
        previewDetail,
        allpreviewDetail,
        paramVal,
        ItemIndex,
      })
      break
    case 'cash-book':
      switch (docType) {
        case 'payment':
          Object.assign(paramVal, CBPayment)
          break
        case 'receipt':
          Object.assign(paramVal, CBReceipt)
          break
        case 'bank-transfer':
          Object.assign(paramVal, CBBankTransfer)
          break
      }
      break
    case 'inventory-control':
      switch (docType) {
        case 'stock-requisition':
          Object.assign(paramVal, ICStockReqquisition)
          break
        case 'stock-receipt':
          Object.assign(paramVal, ICStockRecceipt)
          break
        case 'stock-issue':
          Object.assign(paramVal, ICStockIssuue)
          break
        case 'stock-transfer':
          Object.assign(paramVal, ICStockTransfer)
          break
        case 'stock-transfer-requisition':
          Object.assign(paramVal, ICStockTransfreRequisition)
          break
        case 'stock-adjustment':
          Object.assign(paramVal, ICStockAdjustment)
          break
        case 'stock-cost-adjustment':
          Object.assign(paramVal, ICStockCostAdjustment)
          break
      }
      break
  }

  return paramVal ?? {}
}

export function groupItemData(
  element,
  arrItem,
  Itemindex,
  threshold,
  groupKeys,
  sumValueKeys
) {
  const regex = /\[(.*?)\]/g
  const matches = []
  let match

  while ((match = regex.exec(element.innerHTML)) !== null) {
    matches.push(match[1])
  }

  const filteredArr = matches.filter(value => groupKeys.includes(value))

  const groupedItems: any[] = arrItem.reduce((acc, item) => {
    const key = filteredArr.map(k => item[`[${k}]`]).join('-')

    if (!acc[key]) {
      acc[key] = filteredArr.reduce(
        (obj, k) => {
          obj[`[${k}]`] = item[`[${k}]`]
          return obj
        },
        {
          '[Quantity]': 0,
          '[TaxAmt]': 0,
          '[TotalAmt]': 0,
          '[BaseAmt]': 0,
        }
      )
    }

    sumValueKeys?.map(x => {
      acc[key][`[${x?.column}]`] += parseFloat(
        item[`[${x?.column}]`].replace(/,/g, '')
      )
    })

    return acc
  }, {})

  const result = Object.values(groupedItems)
    .filter((x, i) => i >= Itemindex && i + 1 <= Itemindex + 15)
    .map((item, index) => {
      let obj = {
        '[Index]': index + 1 + Itemindex, // Adding index starting from 1
        ...item,
      }
      sumValueKeys?.map(el => {
        obj[`[${el?.column}]`] =
          el?.decimal === 4
            ? amtStr4Dec(item[`[${el?.column}]`])
            : amtStr(item[`[${el?.column}]`])
      })
      return obj
    })

  return result
}

export function extractItemValue(
  htmlString,
  arrItem,
  docType,
  Itemindex,
  threshold
) {
  let parser = new DOMParser()
  let doc = parser.parseFromString(htmlString, 'text/html')
  let element = doc.getElementById('ItemValue')
  let ItemData = arrItem

  if (docType === 'direct-po') {
    ItemData = groupItemData(
      element,
      arrItem,
      Itemindex,
      threshold,
      ['ItemDescription', 'Department', 'UOM', 'UnitPrice'],
      [
        { column: 'Quantity', decimal: 4 },
        { column: 'TaxAmt', decimal: 2 },
        { column: 'TotalAmt', decimal: 2 },
        { column: 'BaseAmt', decimal: 2 },
      ]
    )
  }

  const replaceItemValue = (itemhtml, obj) => {
    var keys = Object.keys(obj)
      .map(key => key.replace(/[\[\]]/g, '\\$&'))
      .join('|')
    var regex = new RegExp(keys, 'gi')

    return itemhtml.replace(regex, function(matched) {
      return obj[matched]
    })
  }

  if (element && ItemData && ItemData?.length > 0) {
    const container = ItemData.map(x =>
      replaceItemValue(element.innerHTML, x)
    ).join(' ')

    element.innerHTML = container

    return doc.documentElement.outerHTML
  }

  return htmlString
}

export const extractHtmlValue = (
  htmlTemplate,
  accountType,
  docType,
  previewDetail,
  allpreviewDetail,
  getCompany,
  page,
  pageLength,
  getUsersByAccountAndSoftware,
  threshold
) => {
  const Itemindex = threshold * page

  var mapObj: any = moduleParams(
    accountType,
    docType,
    previewDetail,
    allpreviewDetail,
    getCompany,
    page,
    pageLength,
    getUsersByAccountAndSoftware,
    Itemindex
  )

  var keys = Object.keys(mapObj)
    .map(key => key.replace(/[\[\]]/g, '\\$&'))
    .join('|')
  var regex = new RegExp(keys, 'gi')

  let htmlContent = htmlTemplate
  if (!!htmlTemplate) {
    htmlContent = htmlTemplate
      .replace(regex, function(matched) {
        return mapObj[matched]
      })
      .replace(/border-top: 1px dotted #ccc;/g, '')
      .replace(/border-bottom: 1px dotted #ccc;/g, '')

    if (mapObj?.items?.length > 0) {
      htmlContent = extractItemValue(
        htmlContent,
        mapObj?.items,
        docType,
        Itemindex,
        threshold
      )
    }
    htmlContent = htmlContent.replace(/\[.*?\]/g, '')
  }

  return htmlContent
}
